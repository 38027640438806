import { TransactionApiService } from '@api/transaction/transaction.api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TransactionDataResult } from '../../types/DataResult';

@Injectable()
export class TransactionRepositoryService {
  public constructor(private readonly transactionApi: TransactionApiService) {}

  public getActiveTransactions(params: {
    Issuer?: boolean;
    ChargingStationId?: string[];
    WithCompany?: boolean;
    WithSite?: boolean;
    WithSiteArea?: boolean;
    WithTag?: boolean;
    WithUser?: boolean;
    WithCar?: boolean;
    WithChargingStation?: boolean;
    Statistics?: 'ongoing';
    Limit: number;
  }): Observable<TransactionDataResult> {
    const requestParams = { ...params, ChargingStationId: params.ChargingStationId?.join('|') };

    return this.transactionApi.getActiveTransactions(requestParams);
  }
}
