import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RESTServerRoute } from '../../types/Server';
import { TransactionDataResult } from '../../types/DataResult';

@Injectable()
export class TransactionApiService {
  public constructor(private readonly httpClient: HttpClient) {}

  public getActiveTransactions(params: {
    Issuer?: boolean;
    ChargingStationId?: string;
    WithCompany?: boolean;
    WithSite?: boolean;
    WithSiteArea?: boolean;
    WithTag?: boolean;
    WithUser?: boolean;
    WithCar?: boolean;
    WithChargingStation?: boolean;
    Statistics?: 'ongoing';
    Limit: number;
  }): Observable<TransactionDataResult> {
    return this.httpClient.get<TransactionDataResult>(RESTServerRoute.REST_TRANSACTIONS_ACTIVE, {
      params,
    });
  }
}
