import { ActionResponse } from '../../../types/DataResult';
import {
  CertificateHashData,
  DeleteCertificateCommandParam,
  GetDiagnosticsCommandParam,
  GetInstalledCertificatesIdsCommandParam,
  GetLogCommandParam,
  InstallCertificateCommandParam,
  SendLocalListCommandParam,
  TriggerMessageCommandParam,
  UpdateFirmwareCommandParam,
} from '../../../types/ocpp/OCPP';

export interface GetLogsFileResponse extends ActionResponse {
  status: string;
  filename: string;
}

export enum Command {
  GET_LOG = 'GetLog',
  GET_DIAGNOSTICS = 'GetDiagnostics',
  UPDATE_FIRMWARE = 'UpdateFirmware',
  DELETE_CERTIFICATE = 'DeleteCertificate',
  GET_INSTALLED_CERTIFICATE_IDS = 'GetInstalledCertificateIds',
  INSTALL_CERTIFICATE = 'InstallCertificate',
  SEND_LOCAL_LIST = 'SendLocalList',
  GET_LOCAL_LIST_VERSION = 'GetLocalListVersion',
  TRIGGER_MESSAGE = 'TriggerMessage',
}

export type bodyType =
  | GetDiagnosticsCommandParam
  | GetLogCommandParam
  | UpdateFirmwareCommandParam
  | DeleteCertificateCommandParam
  | GetInstalledCertificatesIdsCommandParam
  | CertificateHashData
  | InstallCertificateCommandParam
  | SendLocalListCommandParam
  | TriggerMessageCommandParam;
