import { Injectable } from '@angular/core';
import { ReservationApiService } from '@api/reservation/reservation.api.service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConnectorReservationDo } from '@api/reservation/dto/connector-reservation.do';
import { ChargingStation } from '../../types/ChargingStation';

@Injectable()
export class ReservationRepositoryService {
  public constructor(private readonly api: ReservationApiService) {}

  public getReservations(chargingStation: ChargingStation): Observable<ConnectorReservationDo[]> {
    return forkJoin(
      chargingStation.connectors.map((connector) => {
        return this.api.getReservation(chargingStation.id, connector.connectorId).pipe(catchError(() => of(undefined)));
      }),
    ).pipe(map((reservations = []) => reservations.filter((reservation) => reservation !== undefined)));
  }
}
